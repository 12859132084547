<template>
  <div class="investment-manage-dialog">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="dialogCloseHandler"
      width="860px"
      title="招商管理配置"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-form
        label-position="top"
        :model="form"
        ref="dialogForm"
        :rules="formRule"
      >
        <el-form-item label="项目" prop="projectId">
          <el-select
            style="width: 100%"
            v-model="form.projectId"
            placeholder="请选择项目"
            :disabled="dataset.datas"
          >
            <el-option
              v-for="item in projectList"
              :value="item.projectId"
              :label="item.projectName"
              :key="item.projectId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input
            maxlength="16"
            placeholder="请输入标题"
            v-model="form.title"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input
            type="textarea"
            maxlength="50"
            placeholder="请输入描述"
            v-model="form.description"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="iphone">
          <el-input placeholder="请输入电话" v-model="form.iphone"></el-input>
        </el-form-item>
        <el-form-item label="上传图片" prop="photoIdListId">
          <div class="displayImgTip">
            温馨提示：最多上传5张，图片大小不能超过2m，图片格式为jpg，png。
            图片尺寸 335*225像素
          </div>
          <div style="width: 80%" v-loading="uploadloading">
            <el-upload
              :action="uploadPath"
              :limit="5"
              :on-success="handleAvatarSuccess"
              :file-list="fileList"
              :before-upload="beforeAvatarUpload"
              list-type="picture-card"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
      <div class="button-line">
        <r-button type="cancel" @click="dialogCloseHandler">取消</r-button>
        <r-button
          plain
          @click="submitHandler"
          :loading="uploadloading || submitLoading"
          >保存</r-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";
import { addInvestment, editInvestment } from "@/api/ruge/lego/config";

export default {
  name: "investment-manage-dialog",
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
        };
      },
    },
    projectList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      submitLoading: false,
      uploadloading: false,
      fileList: [],
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
      form: {
        projectId: null,
        title: null,
        description: null,
        iphone: null,
        photoIdListId: [],
      },
      formRule: {
        projectId: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        title: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        description: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        iphone: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        photoIdListId: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
          {
            validator: (rule, value, callback) => {
              if (!this.form.photoIdListId.length) {
                callback(new Error("请上传图片"));
              }
              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  watch: {
    dataset: {
      handler(rowData) {
        if (rowData.datas) {
          const { projectId, title, description, iphone, photoIdListId, id } =
            rowData.datas;
          this.form.projectId = projectId;
          this.form.title = title;
          this.form.description = description;
          this.form.iphone = iphone;
          this.form.photoIdListId = photoIdListId;
          this.form.id = id;
          this.fileList = photoIdListId.map((fileId) => {
            return {
              url: `${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${fileId}`,
              response: [{ fileId }],
            };
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    dialogCloseHandler(flag) {
      this.$emit("close", flag);
    },
    handleAvatarSuccess(res, file, fileList) {
      const currentId = file.response[0].fileId;
      this.form.photoIdListId.push(currentId);
      this.uploadloading = false;
      this.$refs.dialogForm.validateField("photoIdListId");
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error("上传头像图片只能是 JPG,PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      const returnFlag = (isJPG || isPNG) && isLt2M;
      this.uploadloading = returnFlag;
      return returnFlag;
    },
    handleRemove(file, fileList) {
      this.form.photoIdListId = fileList.map((item) => item.response[0].fileId);
    },
    async submitHandler() {
      await this.$refs.dialogForm.validate();
      this.submitLoading = true;
      const params = { ...this.form };
      console.log("params", params);
      if (this.dataset.datas) {
        editInvestment(params)
          .then((res) => {
            this.$message.success("修改成功！");
            this.$emit("close", true);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      } else {
        addInvestment(params)
          .then(() => {
            this.$message.success("保存成功！");
            this.$emit("close", true);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.investment-manage-dialog {
  .displayImgTip {
    font-size: 14px;
    color: #909399;
  }

  .avatar-uploader {
    border: 1px dotted #ccc;
    height: 178px;
    width: 178px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .button-line {
    text-align: right;
    margin: 20px 0;
  }
}
</style>