<template>
  <div class="lego-investment-management app-container">
    <div class="button-line">
      <r-button plain @click="addNewConfig">新增配置</r-button>
      <r-button @click="initDatas">刷新</r-button>
    </div>
    <div class="table-container">
      <FinalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <InvestManageDialog
      :dataset="investmentDialog"
      v-if="investmentDialog.show"
      :projectList="projectList"
      @close="dialogClose"
    />
  </div>
</template>

<script>
import FinalTable from "@/components/FinalTable";
import InvestManageDialog from "./components/investManageDialog.vue";
import {
  getInvestmentList,
  getMiniprogramList,
  upInvestment,
  deleteInvestment,
} from "@/api/ruge/lego/config";
import { getProjectList } from "@/api/business/base/tenant/map";
export default {
  name: "lego-investment-management",
  components: {
    FinalTable,
    InvestManageDialog,
  },
  data() {
    return {
      loadingFlag: false,
      projectList: [],
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        header: [
          { prop: "projectId", label: "项目", width: "" },
          { prop: "sortNumber", label: "排序序号", width: "" },
          { prop: "title", label: "标题", width: "" },
          { prop: "description", label: "描述", width: "" },
          { prop: "iphone", label: "电话", width: "" },
          { prop: "updateByStr", label: "修改人", width: "" },
          { prop: "updateTime", label: "修改时间", width: "" },
          { prop: "operation", label: "操作", width: "150" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          projectId: {
            type: "selector",
            label: "项目",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择项目",
            optionList: [],
          },
        },
        // 表格内容配置
        detailConfig: {
          projectId: {
            type: "otherParam",
            paramName: "projectName",
          },
          cshStatus: {
            type: "switch",
            actionType: "switchEvent",
            eventName: "cshStatusChange",
          },
          creationDate: {
            type: "dateFormat",
          },
          lastUpdateDate: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "toTop",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-document-copy",
                tooltips: "置顶",
              },
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-document-copy",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      filterObj: {
        current: 1,
        rowCount: 10,
      },
      investmentDialog: {
        show: false,
        datas: null,
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.initDatas();
    this.initMiniOptions();
  },
  methods: {
    initDatas() {
      this.loadingFlag = true;
      getInvestmentList(this.filterObj)
        .then((res) => {
          this.dataset.tableData = res.data.rows;
          this.dataset.pageVO.total = res.data.total;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    initMiniOptions() {
      getProjectList().then((res) => {
        this.projectList = res || [];
        this.dataset.searchLineConfig.projectId.optionList = res.map((item) => {
          return {
            value: item.projectId,
            label: item.projectName,
          };
        });
      });
      // getMiniprogramList().then((res) => {
      //   const miniList = res
      //     .reduce((result, currentValue) => {
      //       return result.concat(currentValue.miniprogramList);
      //     }, [])
      //     .map((single) => {
      //       return {
      //         value: single.miniprogramId,
      //         label: single.miniprogramTitle,
      //       };
      //     });
      //   this.projectList = miniList;
      //   this.dataset.searchLineConfig.miniprogramId.optionList = miniList;
      // });
    },
    addNewConfig() {
      this.investmentDialog.show = true;
      this.investmentDialog.datas = null;
    },
    editHandler(rowData) {
      this.investmentDialog.show = true;
      this.investmentDialog.datas = rowData;
    },
    dialogClose(flag) {
      this.investmentDialog.show = false;
      flag === true && this.initDatas();
    },
    toTopHandler({ id }) {
      upInvestment(id).then(() => {
        this.$message.success("置顶成功！");
        this.initDatas();
      });
    },
    async deleteHandler({ id }) {
      await this.$confirm(
        `确定删除该条招商信息吗？`,
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: "再想想",
          type: "warning",
        }
      );
      deleteInvestment(id).then(() => {
        this.$message.success("删除成功！");
        this.initDatas();
      });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.filterObj.current = 1;
        this.filterObj = { ...this.filterObj, ...datas.params };
        if (!this.filterObj.miniprogramId) {
          delete this.filterObj.miniprogramId;
        }
        this.initDatas();
      } else if (datas.type === "paginationChange") {
        this.filterObj.current = datas.params.current.page;
        this.filterObj.rowCount = datas.params.current.limit;
        this.initDatas();
      }
      if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "toTop":
            this.toTopHandler(datas.row);
            break;
          case "edit":
            this.editHandler(datas.row);
            break;
          case "delete":
            this.deleteHandler(datas.row);
            break;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.lego-investment-management {
  .button-line {
    margin-bottom: 10px;
  }
}
</style>